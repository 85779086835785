@media screen and (max-width: 1000px) {
  .small-video {
    width: 95% !important;
  }
}


@media screen and (max-width: 767px) {
  .center {
    text-align: center !important;
  }

  .margin-auto {
    margin: auto !important;
  }

  .padding-mobile {
    padding-top: 2rem !important;
  }
}
